$(function () {
    //clear session storage for the calendar on log out.
    window.window.sessionStorage.setItem("tsCalendar-checkbox-state",JSON.stringify([]));
    window.window.sessionStorage.setItem("tsCalendar-state",JSON.stringify([]));

    // set the focus on the username field if it loads blank
    // otherwise its prefilled with a saved value so focus on the password
    setTimeout(function () {
        if ($("#username").val() === "")
            $("#username").trigger("focus");
        else
            $("#password").trigger("focus");
    }, 500);

    // Language change
    $('#LocaleSelection').on('change', function () {
        window.location = 'default.cfm?page=Login&setUserLocale=' + $(this).val();
    });

});