$(function(){

  // Actions
  $(".loginButton")
    .on("click",function(e){
      e.preventDefault();
      $("form[name=LoginForm]")
        .find("input[name=PerformClockAction]")
        .val($(this).attr("data-login-value"))
        .end()
        .trigger("submit");
    });
  
  // Reveal/hide password with the "eyeball icon"
  var readable = false;
  $(".pw-toggle").on("click", function(){    
    if(readable){
      $("#password").attr("type", "password");
    }
    else {
      $("#password").attr("type", "text");
    }
    readable = !readable;
  });


});